import { ComponentType, PropsWithChildren } from "react";

import { Stack, useTheme } from "@bps/fluent-ui";
import { Notifications } from "@components/Notifications";

import { NavBar } from "./nav-bar/NavBar";

export interface MainLayoutProps {
  toolbar?: ComponentType;
}

export const MainLayout = (props: PropsWithChildren<MainLayoutProps>) => {
  const { semanticColors } = useTheme();
  document.body.style.backgroundColor = semanticColors.disabledBackground;

  return (
    <Stack
      verticalFill
      styles={{ root: { backgroundColor: semanticColors.disabledBackground } }}
    >
      <NavBar />
      <Notifications />

      <Stack
        as="main"
        styles={{
          root: {
            margin: 16,
            position: "relative"
          }
        }}
        grow
      >
        {props.children}
      </Stack>
    </Stack>
  );
};
